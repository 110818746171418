import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {ICrAuthTokens, ITokens} from '../session/session.interfaces';
import {ILoginInfo} from '@common/interfaces';
import {IRestResponse} from "@kkm-ui/utils/requests";

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(
    private http: HttpClient,
  ) {
  }

  signIn(credentials: ILoginInfo): Observable<ICrAuthTokens | undefined> {
    return this.http.post<ICrAuthTokens>('/api/v2/cash-register/auth/login', credentials)
      .pipe(first());
  }

  start(regNumber: string): Observable<IRestResponse<ITokens>> {
    return this.http
      .get<ITokens>(`/api/v2/cash-register/work/start/${regNumber}`)
      .pipe(first())
      .pipe(
        map((response: ITokens) => ({status: !!response ? 200 : 204, response} as IRestResponse<ITokens>)),
        catchError((error: HttpErrorResponse) => of({status: error.status || 500} as IRestResponse<ITokens>))
      );
  }
}
