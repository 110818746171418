import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({providedIn: 'root'})
export class QueryParamsService {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  parse(opts: {key?: string, str?: string} | null | undefined): any {
    let {key = 'p', str = undefined} = (opts || {});
    str = !str ? this.route.snapshot.queryParams[key] : str;
    return str ? JSON.parse(
      decodeURIComponent(
        atob(str.startsWith('?') ? str.slice(1) : str)
          .split('')
          .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      )
    ) : {};
  }

  stringify(obj: any): string {
    return btoa(
      encodeURIComponent(JSON.stringify(obj || {}))
        .replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(`0x${p1.toString()}`, 16)))
    );
  }
}
