import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SignInComponent} from './components/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in',
  },
  {
    path: 'sign-in',
    pathMatch: 'full',
    component: SignInComponent,
  },
  {
    path: 'choose-cr',
    loadChildren: () => import('./components/choose-cr/choose-cr.module').then(m => m.ChooseCrModule),
  },
  {
    path: 'cr-deregistered',
    loadChildren: () => import('./components/cr-deregistered-page/cr-deregistered-page.module').then(m => m.CrDeregisteredPageModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./inner-app/inner-app.module').then(m => m.InnerAppModule),
  },
  {
    path: '**',
    redirectTo: '/app/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
