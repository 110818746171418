<ng-container *ngIf="!errorState; else isError">
  <div class="d-flex h-fluid align-items-center justify-content-center" *transloco="let t, read: 'page.sign-in'">
    <form class="sign_in-form" [formGroup]="form" (ngSubmit)="handleSignIn()" focusFirstInvalid>
      <h1 class="text-center m_b-2" [textContent]="t('title')"></h1>
      <h2 class="text-center m_b-12" [textContent]="t('subtitle')"></h2>

      <div class="d-flex justify-content-between align-items-center m_b-6">
        <span class="body_text" [textContent]="t('login-msg')"></span>
        <kkm-lang-select [default]="selectedLang" [languages]="languages" (selected)="selectLanguage($event)"></kkm-lang-select>
      </div>

      <p *ngIf="form.getError('password-incorrect')" [textContent]="t('form.errors.incorrect')" class="fail-area webcr body_text w-fluid align-left m_b-2"></p>
      <p *ngIf="error" [textContent]="error" class="fail-area webcr body_text w-fluid align-left m_b-2"></p>

      <mat-form-field appearance="fill" class="kkm-form-field kkm-field-error webcr w-fluid m_b-6">
        <mat-label [textContent]="t('form.fields.email.label')"></mat-label>
        <input matInput type="text" name="email" formControlName="login" clearIfCorrect>
        <mat-error *ngIf="loginControl.dirty && loginControl.touched && loginControl.invalid">
          {{t('form.fields.email.required-error')}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="kkm-form-field kkm-field-error webcr w-fluid m_b-6" [class.eye-button_visible-by-input]="passwordControl.value || isFocused(passwordInput)"><!-- outline fill-->
        <mat-label [textContent]="t('form.fields.password.label')"></mat-label>
        <input #passwordInput matInput [type]="isPasswordHidden ? 'password' : 'text'" name="password" autocomplete="off" formControlName="password" clearIfCorrect>
        <button type="button" class="kkm-button eye-button eye-button_visible-by-hover eye-button__webcr" mat-icon-button matSuffix
                (click)="togglePasswordVisibility($event)">
          <mat-icon [svgIcon]="isPasswordHidden ? 'eye:on' : 'eye:off'"></mat-icon>
        </button>
        <mat-error *ngIf="passwordControl.dirty && passwordControl.touched && passwordControl.invalid">
          {{t('form.fields.password.required-error')}}
        </mat-error>
      </mat-form-field>

      <div class="w-fluid m_b-8">
        <button
          type="submit"
          class="kkm-button login-button webcr w-fluid"
          mat-button
          color="primary"
          [class.kkm-button_loading]="isLoading"
          [disabled]="isLoading">
          <span *ngIf="!isLoading">{{t('form.buttons.submit')}}</span>
        </button>
      </div>
    </form>
  </div>
</ng-container>
<ng-template #isError>
  <kkm-aiswebcr-server-error-screen></kkm-aiswebcr-server-error-screen>
</ng-template>
