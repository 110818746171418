// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaults: {
    language: 'en-US',
  },
  fnExpirationWarningDays: 30,
  fnExpirationWarningDaysForShiftReport: 7,
  cloudUrl: 'http://kkmnewsrv18.fls.cloud:30115/',
  shared: {
    services: {
      currency: {
        createEndpoint: '/api/currency/create',
        updateEndpoint: '/api/currency/update',
        deleteEndpoint: (id: number) => `/api/currency/delete/${id}`,
        listEndpoint: '/api/currency/list',
      }
    },
  },
  dictionaries: {
    taxSystems: '/api/info/tax-systems',
    businessActivities: '/api/info/business-activities',
    entrepreneurshipObjects: '/api/info/entrepreneurship-objects',
    taxAuthorityDepartments: '/api/info/tax-authority-departments',
  },
  useCoords: true,
  defaultLocation: {
    lat: 59.938955,
    lng: 30.315644,
  },
  currency: '',
  isUnlimitedFm: true,
  hideCorrections: true,
  tinPattern: '\\d{14}',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
