import {NgModule} from "@angular/core";
import {SessionExpiredDialogComponent} from "./session-expired-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {UiModule} from '@kkm-ui/ui';
import {environment} from '@ais-webcr/env';
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {TranslocoRootModule} from "../../transloco-root.module";

@NgModule({
  declarations: [SessionExpiredDialogComponent],
  imports: [
    MatDialogModule,
    MatIconModule,
    TranslocoRootModule,
    UiModule.forRoot(environment),
    MatButtonModule,
    CommonModule,
  ],
  exports: [SessionExpiredDialogComponent]
})

export class SessionExpiredDialogModule {
}
