import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ICalcItemAttributes} from "@common/interfaces";

@Injectable({
  providedIn: 'root',
})
export class CalcItemAttributesService {

  private _calcItemAttributes: ICalcItemAttributes[] = [];

  constructor(private http: HttpClient) {
  }

  get calcItemAttributes(): ICalcItemAttributes[] {
    return this._calcItemAttributes.sort((a, b) => a.code - b.code);
  }

  async refreshCalcItemAttributes() {
    try {
      this._calcItemAttributes = await this.http.get<ICalcItemAttributes[]>('/api/info/calc-item-attributes').toPromise();
    } catch (err) {
      console.error(err);
    }
  }

}

