import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ICrAuthTokens} from '../../services/session/session.interfaces';
import {SessionService} from '../../services/session/session.service';
import {AuthService} from '../../services/auth/auth.service';
import {ILang, ILoginInfo} from '@common/interfaces';
import {TranslateService} from '../../../../../../libs/ui/src/lib/services/translate.service';
import {TranslocoService} from '@ngneat/transloco';
import {SettingsService} from '../../services/settings/settings.service';

@Component({
  selector: 'ais-webcr-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.styl']
})
export class SignInComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public isLoading = false;
  isPasswordHidden: boolean = true;
  public error: string = null;
  public languages = SettingsService.LANGUAGES;

  errorState: boolean = false;
  subscription: Subscription = Subscription.EMPTY;

  destroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private translocoService: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.settingsService.errorTrigger.subscribe(() => this.errorState = true);
    this.sessionService.close();
    this.form = this.fb.group({
      login: [{value: '', disabled: this.isLoading}, [Validators.required]],
      password: [{value: '', disabled: this.isLoading}, [Validators.required]]
    });
    this.form.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      if (this.form.hasError('password-incorrect')) {
        delete this.form.errors['password-incorrect'];
        this.form.updateValueAndValidity();
      }
    });
    try {
      const authParam = this.route.snapshot.queryParams.auth;
      if (this.route.snapshot.queryParams.auth) {
        const { login, password } = JSON.parse(atob(authParam))
        this.form.setValue({
                             login,
                             password
                           })
        this.handleSignIn()
      }
    } catch (e) {
      console.error(e)
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subscription.unsubscribe();
  }

  handleSignIn(): void {
    this.error = null;
    if (this.form.valid) {
      this.isLoading = true;
      this.form.setErrors(null);
      this.authService
        .signIn(this.form.value as ILoginInfo).toPromise()
        .then((authResponse: ICrAuthTokens | undefined) => this.onSignInSuccess(authResponse))
        .catch((error) => {
          this.isLoading = false;
          if (error.status === 401) {
            this.form.setErrors({'password-incorrect': true});
          } else if (error.status === 406) {
            this.error = error.error?.error;
          }
        });
    }
  }

  selectLanguage(lang: ILang) {
    this.settingsService.setLanguage(lang.value);
    this.translateService.setLocale(lang.value);
    this.translocoService.setActiveLang(lang.value);
  }

  get selectedLang() {
    return SettingsService.language;
  }

  private onSignInSuccess(authResponse: ICrAuthTokens | undefined) {
    this.isLoading = false;
    if (authResponse) {
      this.sessionService.openCrAuth(authResponse);
      this.navigateToCrAuth();
    }
  }

  private navigateToCrAuth() {
    this.router.navigate(['/choose-cr'],{replaceUrl: true});
  }

  get loginControl(): FormControl {
    return this.form.get('login') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.form.get('password') as FormControl;
  }

  togglePasswordVisibility(ev: MouseEvent) {
    ev.stopPropagation();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  isFocused(el: Element) {
    return document.activeElement === el;
  }
}
