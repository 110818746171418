import {Component} from "@angular/core";

@Component({
  selector: 'kkm-aiswebcr-server-error-screen',
  templateUrl: './server-error-screen.component.html',
  styleUrls: ['./server-error-screen.component.styl']
})
export class ServerErrorScreenComponent {

  constructor() { }

}
