import { IUser } from './session.interfaces';
import { IAddress } from '@common/interfaces';
import { calculateDaysLeft } from '@kkm-ui/utils/date-time';
import { CalculationTypes, CalculationTypesCodes } from '@common/enums';
import { IPrincipal } from "../../app.interfaces";
import { v4 as uuidv4 } from 'uuid';

export class User implements IUser {
  name: string;
  login: string;
  taxSystems: number[];
  vatPayer: boolean;
  calcItemAttributes: number[];
  entrepreneurshipObject: number;
  businessActivity: number;
  taxAuthorityDepartment: number;
  currencyCatalogUpdateDate: Date;
  address: IAddress;
  isEmpty = true;
  fnExpiresAt: string;
  tin: string;
  principals: IPrincipal[];

  crInactive: boolean;

  static _instance: User;

  public static getInstance(opts?: IUser): User {
    if (!this._instance) {
      this._instance = new this();
    }
    if (opts) {
      this._instance.init(opts);
    }
    return this._instance;
  }

  public static clearInstance(): void {
    this._instance = undefined;
  }

  public static setInactiveStatus(status: boolean) {
    if (!this._instance) {
      this._instance = new this();
    }
    this._instance.crInactive = status;
  }

  constructor() {
    this.name = '';
    this.login = '';
    this.taxSystems = [];
    this.calcItemAttributes = [];
    this.address = null;
    this.isEmpty = true;
    this.fnExpiresAt = null;
    this.tin = null;
    this.crInactive = false;
    this.currencyCatalogUpdateDate = null;
    this.principals = [];
  }

  get formattedAddress(): string {
    return this.address ? this.address.formattedString : '';
  }

  get fnExpiresAtAsDate(): Date | null {
    return !!this.fnExpiresAt ? new Date(this.fnExpiresAt) : null;
  }

  get fnActivationDaysLeft(): number | null {
    if (!!this.fnExpiresAt) {
      return calculateDaysLeft(this.fnExpiresAtAsDate);
    }
    return null;
  }

  get isFnExpired(): boolean {
    return !!this.fnExpiresAt && this.fnActivationDaysLeft === 0;
  }

  get isCrInactive(): boolean {
    return this.crInactive;
  }

  get isCrBlocked() {
    return this.isFnExpired || this.isCrInactive;
  }

  get currencyExchangeCalcTypesCodes(): CalculationTypesCodes[] {
    return [
      CalculationTypesCodes.ForeignCurrencyExchange,
    ];
  }

  get isCurrencyExchange(): boolean {
    return this.calcItemAttributes.every((itemAttrCode: number) => this.currencyExchangeCalcTypesCodes.includes(itemAttrCode));
  }

  get notaryOrLawyerCalcTypesCodes(): CalculationTypesCodes[] {
    return [
      CalculationTypesCodes.Services,
      CalculationTypesCodes.StateTax,
    ];
  }

  get isNotaryOrLawyer(): boolean {
    return this.calcItemAttributes.every((itemAttrCode: number) => this.notaryOrLawyerCalcTypesCodes.includes(itemAttrCode));
  }

  get calculationType(): CalculationTypes[] {
    const calcTypes = []; // TODO HARDCODE
    if (this.calcItemAttributes?.filter(c => c !== CalculationTypesCodes.CashOutflow).length > 0) {
      calcTypes.push(CalculationTypes.SALE);
    }

    if (this.calcItemAttributes?.includes(CalculationTypesCodes.CashOutflow) || this.calcItemAttributes?.includes(CalculationTypesCodes.ForeignCurrencyExchange)) {
      calcTypes.push(CalculationTypes.EXPENSE);
    }

    return calcTypes;
  }

  private init(opts: IUser) {
    this.name = opts.name;
    this.login = opts.login;
    this.taxSystems = opts.taxSystems;
    this.vatPayer = opts.vatPayer;
    this.calcItemAttributes = opts.calcItemAttributes;
    this.entrepreneurshipObject = opts.entrepreneurshipObject;
    this.businessActivity = opts.businessActivity;
    this.taxAuthorityDepartment = opts.taxAuthorityDepartment;
    this.currencyCatalogUpdateDate = opts.currencyCatalogUpdateDate;
    this.address = opts.address;
    this.isEmpty = false;
    this.fnExpiresAt = opts.fnExpiresAt;
    this.tin = opts.tin;
    this.principals = (opts.principals || []).map((principal: IPrincipal) => ({
      ...principal,
      uuid: uuidv4(),
    }));
  }
}
