import { Component } from '@angular/core';
import { SettingsService } from './services/settings/settings.service';
import { CalcItemAttributesService } from './services/dictionaries/calc-item-attributes.service';
import { TaxSystemsService } from '../../../../libs/ui/src/lib/services/dictionaries/tax-systems.service';
import { BusinessObjectsDictionaryService } from '../../../../libs/ui/src/lib/services/dictionaries/business-objects-dictionary.service';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
})
export class AppComponent {
  title = 'ais-webcr';

  constructor(
    private settings: SettingsService,
    private taxSystemsService: TaxSystemsService,
    private calcItemAttributesService: CalcItemAttributesService,
    private businessObjectsDictionaryService: BusinessObjectsDictionaryService,
  ) {
    this.taxSystemsService.refreshTaxSystems();
    this.calcItemAttributesService.refreshCalcItemAttributes();
    this.businessObjectsDictionaryService.loadDictionaries();
    this.settings.setTitle();
  }
}
