import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {SettingsService} from "../settings/settings.service";

interface IDeviceInfoHeaders {
  CCRModel: string,
  CCRVersion: string
}

export class DeviceInfoInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const iReq: HttpRequest<any> = this.addDeviceInfoHeaders(req);
    return next.handle(iReq);
  }

  private addDeviceInfoHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const headers: IDeviceInfoHeaders = {
      CCRModel: SettingsService.CrModel,
      CCRVersion: SettingsService.CrVersion
    }

    return req.clone({setHeaders: {...headers}});
  }
}
