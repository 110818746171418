import {Injectable} from "@angular/core";
import {ISaleTax} from "@ais-webcr/constants";
import {SessionService} from "../session/session.service";
import {ITaxRate} from "@common/interfaces";
import {CalculationTypes} from "@common/enums";
import {User} from "../session/session.models";
import {DEFAULT_LANG, LANG_LIST} from "@common/constants";
import {merge, Subject} from "rxjs";
import {setAPILanguage} from '@kkm-ui/utils/google-api-lang';
import {TranslocoService} from "@ngneat/transloco";
import {Title} from "@angular/platform-browser";
import {EnvironmentInitService} from "../../../../../../libs/ui/src/lib/services/environment.service";
import CryptoJS from "crypto-js";

@Injectable({providedIn: 'root'})
export class SettingsService {
  private static PREDEFINED_CR_MODEL = 'Mobile';
  private static PREDEFINED_CR_VERSION = '1';
  private static PREDEFINED_CR_SERIAL_NUMBER = '0000000000000001';
  private static CR_MODEL_KEY = 'cr_model';
  private static CR_VERSION_KEY = 'cr_version';
  private static CR_SERIAL_NUMBER = 'cr_serial_number';
  private static COLLECTION_KEY = 'auto_collection';
  private static ADVANCE_PAYMENT_TYPE_KEY = 'advance_payment_type';
  private static CREDIT_PAYMENT_TYPE_KEY = 'credit_payment_type';
  private static AGENCY_SCHEME = 'agency_scheme';
  private static DEFAULT_CALC_TYPE_KEY = 'calculation_type';

  private static LANG_KEY = 'app_lang';
  public static LANGUAGES = LANG_LIST;

  errorTrigger = new Subject<void>();
  stateMapComponents: Subject<boolean> = new Subject();

  public static get AVAILABLE_LANGUAGES() {
    return SettingsService.LANGUAGES.map(e => e.value);
  }

  constructor(
    private sessionService: SessionService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private environmentService: EnvironmentInitService
  ) {
  }

  get user(): User {
    return User.getInstance();
  }

  static get CrModel(): string {
    return localStorage.getItem(this.CR_MODEL_KEY) || this.PREDEFINED_CR_MODEL;
  }

  static get CrVersion(): string {
    return localStorage.getItem(this.CR_VERSION_KEY) || this.PREDEFINED_CR_VERSION;
  }

  static get CrSerialNumber(): string {
    return localStorage.getItem(this.CR_SERIAL_NUMBER) || this.PREDEFINED_CR_SERIAL_NUMBER;
  }

  static get language(): string {
    return localStorage.getItem(this.LANG_KEY) || DEFAULT_LANG.value;
  }

  async initMapLang() {
    await setAPILanguage(SettingsService.language, this.environmentService.config?.common?.googleApiKey, true);
  }

  async setLanguage(value: string) {
    localStorage.setItem(SettingsService.LANG_KEY, value);
    await this.reloadMaps(value);
  }

  get autoCollection(): boolean {
    const state = localStorage.getItem(this.mapStorageKey(SettingsService.COLLECTION_KEY));
    return state ? (state === '1' ? true : false) : true;
  }

  get advancePaymentType(): boolean {
    const state = localStorage.getItem(this.mapStorageKey(SettingsService.ADVANCE_PAYMENT_TYPE_KEY));
    return state ? (state === '1') : false;
  }

  get creditPaymentType(): boolean {
    const state = localStorage.getItem(this.mapStorageKey(SettingsService.CREDIT_PAYMENT_TYPE_KEY));
    return state ? (state === '1') : false;
  }

  get defaultCalcType(): CalculationTypes {
    const state = localStorage.getItem(this.mapStorageKey(SettingsService.DEFAULT_CALC_TYPE_KEY));
    const defaultState = (state ? (state === 'SALE' ? CalculationTypes.SALE : CalculationTypes.EXPENSE) : CalculationTypes.SALE);
    return this.user.calculationType.length === 1 ? this.user.calculationType[0] : defaultState;
  }

  get agencyScheme(): boolean {
    const state = localStorage.getItem(this.mapStorageKey(SettingsService.AGENCY_SCHEME));
    return state ? (state === '1' ? true : false) : false;
  }

  setAutoCollection(value: boolean) {
    localStorage.setItem(this.mapStorageKey(SettingsService.COLLECTION_KEY), value ? '1' : '0');
  }

  setAdvancePaymentType(value: boolean): void {
    localStorage.setItem(this.mapStorageKey(SettingsService.ADVANCE_PAYMENT_TYPE_KEY), value ? '1' : '0');
  }

  setCreditPaymentType(value: boolean): void {
    localStorage.setItem(this.mapStorageKey(SettingsService.CREDIT_PAYMENT_TYPE_KEY), value ? '1' : '0');
  }

  setDefaultCalcType(value: CalculationTypes) {
    localStorage.setItem(this.mapStorageKey(SettingsService.DEFAULT_CALC_TYPE_KEY), value === CalculationTypes.SALE ? 'SALE' : 'EXPENSE');
  }

  setAgencyScheme(value: boolean) {
    localStorage.setItem(this.mapStorageKey(SettingsService.AGENCY_SCHEME), value ? '1' : '0');
  }

  static getTaxRateByCode(taxRates: ITaxRate[], type: string, code: number): ISaleTax {
    const taxRate = taxRates.find(x => x.code === code && x.taxRateType === type);
    return {code: taxRate.code, rate: taxRate.taxRateValue};
  }

  static getTaxByCode(taxes: ISaleTax[], code: number): ISaleTax {
    return taxes.find(x => x.code === code);
  }

  setTitle() {
    return merge(this.translocoService.events$, this.translocoService.langChanges$).subscribe(() => {
      this.titleService.setTitle(this.translocoService.translate('page.sign-in.subtitle'));
    });
  }

  public async getSerialNumber(shopName: string, name: string, tin: string): Promise<string> {
    const model = SettingsService.CrModel;
    const version = SettingsService.CrVersion;
    const hashInput = [shopName, name, tin].join('.');
    const hash = await this.getHash(hashInput);

    return `${model}.${version}.${hash.substring(0, 10)}`;
  }

  private mapStorageKey(key: string) {
    return `${key}_${this.sessionService.activeCr.registrationNumber}`;
  }

  private async reloadMaps(lang: string) {
    this.stateMapComponents.next(false);
    await setAPILanguage(lang, this.environmentService.config?.common?.googleApiKey, true);
    this.stateMapComponents.next(true);
  }

  private async getHash(message: string): Promise<string> {
    if (window.isSecureContext) {
      // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest

      const msgUint8 = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    } else {
      return CryptoJS.SHA256(message).toString();
    }
  }
}
