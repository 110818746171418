<ng-container *transloco="let t, read: 'app.components.session-expired-dialog'">
  <div mat-dialog-title [textContent]="t('title')"></div>
  <button class="mat-dialog-close-button" mat-dialog-close>
    <mat-icon svgIcon="close:dialog"></mat-icon>
  </button>

    <div mat-dialog-content class="body_text">
      <div [style.text-align]="'center'" [textContent]="t('message-1')"></div>
      <div [style.text-align]="'center'" [textContent]="t('message-2')" class="m_b-10"></div>
    </div>

    <div mat-dialog-actions class="body_text d-flex justify-content-end">
      <a mat-dialog-close [textContent]="t('close-button')"></a>
    </div>
</ng-container>
