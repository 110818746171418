import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../settings/settings.service';
import { User } from '../../services/session/session.models';
import { EventBusService, EventData } from '@kkm-ui/ui';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(
    private settings: SettingsService,
    private eventBus: EventBusService,
    private dialog: MatDialog
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
          // Http Response
          }
        }, err => {
          if (err instanceof HttpErrorResponse) {
            // Http Error Response
            if (err.status === 500 || err.status === 502) {
              if (err.url.includes('api/v2/cash-register/work/refresh')) {
                return;
              }

              this.dialog.closeAll();
              this.settings.errorTrigger.next();
            }

            if (err.status === 406 && !err.url.includes('api/cash-register/registration')) {
              User.setInactiveStatus(true);
            }

            if (err.status === 403 && err.error.error === "Shift must be closed") {
              this.eventBus.emit(new EventData('shift/close-shift', null));
            }
          }
        })
      );
  }
}
