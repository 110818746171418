import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap, first} from 'rxjs/operators';
import {ICrAuthTokens, ICrAuthModel, ITokens, IUser, IAvailableCr} from './session.interfaces';
import {User} from './session.models';
import {oc} from 'ts-optchain';
import {PrincipalService} from "../principals/principals.service";

@Injectable()
export class SessionService {
  private readonly TOKENS = 'TOKENS';
  private TEMP_AUTH_INFO_KEY = 'CR_AUTH_MODEL';
  private SELECTED_CR_KEY = 'SELECTED_CR';

  onSetActiveCrBlocked: EventEmitter<boolean>;

  constructor(private http: HttpClient) {
    this.onSetActiveCrBlocked = new EventEmitter<boolean>();
  }

  get tokens(): ITokens | undefined {
    const json: string = localStorage.getItem(this.TOKENS);
    return !!json ? JSON.parse(json) : undefined;
  }

  set tokens(tokens: ITokens | undefined) {
    if (tokens) {
      localStorage.setItem(this.TOKENS, JSON.stringify(tokens));
    } else {
      localStorage.removeItem(this.TOKENS);
    }
  }

  get activeCr(): IAvailableCr {
    const json: string = localStorage.getItem(this.SELECTED_CR_KEY);
    return !!json ? JSON.parse(json) : undefined;
  }

  set activeCr(value: IAvailableCr | undefined) {
    if (value) {
      localStorage.setItem(this.SELECTED_CR_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(this.SELECTED_CR_KEY);
    }
  }

  get activeCrBlocked(): boolean {
    // return true;
    return this.activeCr.blockedByFn || this.activeCr.inactiveCr;
  }

  private get userInstance(): User {
    return User.getInstance();
  }

  setActiveCrDeregistered() {
    this.activeCr = {
      ...this.activeCr,
      deregistered: true
    } as IAvailableCr;
  }

  setActiveCrBlocked() {
    const blockedByFn = this.userInstance.isFnExpired;
    const inactiveCr = this.userInstance.isCrInactive;
    this.activeCr = {...this.activeCr, blockedByFn, inactiveCr} as IAvailableCr;
    this.onSetActiveCrBlocked.emit(true);
  }

  isRefreshTokenExpired(tokens: ITokens = null): boolean {
    tokens = tokens ? tokens : this.tokens;
    return (
      !tokens ||
      !tokens.refreshToken ||
      !tokens.refreshTokenTTL ||
      new Date() > new Date(tokens.refreshTokenTTL)
    );
  }

  open(tokens: ITokens, cr: IAvailableCr = null): void{
    this.tokens = tokens;
    this.activeCr = cr;
  }

  close(): void {
    this.removeModelAndTokens();
    User.clearInstance();
  }

  removeModelAndTokens(): void {
    if (this.crAuthModel) {
      this.writeCrAuthModel(undefined);
    }
    this.tokens = undefined;
  }

  openCrAuth(chooseCrTokens: ICrAuthTokens) {
    this.writeCrAuthModel({
      canRegister: chooseCrTokens.canRegister,
      cashierName: chooseCrTokens.cashierName,
      name: chooseCrTokens.name,
      registrators: chooseCrTokens.registrators,
      tin: chooseCrTokens.tin
    } as ICrAuthModel);
    this.open(chooseCrTokens as ITokens);
  }

  get crAuthModel(): ICrAuthModel {
    const json: string = localStorage.getItem(this.TEMP_AUTH_INFO_KEY);
    return !!json ? JSON.parse(json) : undefined;
  }

  async refreshUser(): Promise<void> {
    const user = await this.http.get<IUser>('/api/auth/me', {headers: {'Access-Token': oc(this.tokens).accessToken('')}}).toPromise();
    User.getInstance(user);
    // this.principalService.principals = user.principals;

    // this.http
    //   .get<IUser>('/api/auth/me', {headers: {'Access-Token': oc(this.tokens).accessToken('')}})
    //   .pipe(first())
    //   .subscribe((user: IUser) => {
    //     User.getInstance(user);
    //     this.principalService.principals = user.principals;
    //
    //     console.log("this.principalService.principals: ", this.principalService.principals);
    //   });
  }

  doRefreshToken() {
    const tokens: ITokens = this.tokens;
    const crAuthModel = !!this.crAuthModel;
    const activeCr = this.activeCr;
    const endpoint = crAuthModel
      ? '/api/v2/cash-register/auth/refresh'
      : '/api/v2/cash-register/work/refresh';
    this.removeModelAndTokens();
    return this.http.post<any>(
      endpoint,
      null,
      {headers: {'Refresh-Token': tokens.refreshToken}},
    ).pipe(
      tap(
        crAuthModel
          ? (t: ICrAuthTokens) => this.openCrAuth(t)
          : (t: ITokens) => this.open(t, activeCr)
      ),
    );
  }

  public writeCrAuthModel(info: ICrAuthModel) {
    if (info) {
      localStorage.setItem(this.TEMP_AUTH_INFO_KEY, JSON.stringify(info));
    } else {
      localStorage.removeItem(this.TEMP_AUTH_INFO_KEY);
    }
  }
}
