import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SessionService} from "./services/session/session.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {LanguageInterceptor} from "./services/session/language.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UiModule} from "@kkm-ui/ui";
import {AppRoutingModule} from './app-routing.module';
import {environment} from "@ais-webcr/env";
import {TranslocoRootModule} from "./transloco-root.module";
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {DeviceInfoInterceptor} from "./services/session/device-info.interceptor";
import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import ky from '@angular/common/locales/ky';
import {registerLocaleData} from '@angular/common';
import {EnvironmentInitService} from '../../../../libs/ui/src/lib/services/environment.service';
import {TokenInterceptor} from './services/session/token.interceptor';
import {ErrorsInterceptor} from './services/session/errors.interceptor';
import {ServerErrorScreenModule} from './components/server-error-screen/server-error-screen.module';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SettingsService } from './services/settings/settings.service';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { SessionExpiredDialogModule } from './components/session-expired-dialog/session-expired-dialog.module';

registerLocaleData(en, 'en');
registerLocaleData(ru, 'ru');
registerLocaleData(ky, 'ky');

export const initializeApp = (appInitService: EnvironmentInitService, settingsService: SettingsService) => {
  return () => {
    return new Promise<void>(async (resolve, reject) => {
      await appInitService.Init();
      await settingsService.initMapLang();
      resolve();
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    UiModule.forRoot(environment),
    TranslocoRootModule,
    AppRoutingModule,
    HttpClientModule,
    ServerErrorScreenModule,
    MatDialogModule,
    SessionExpiredDialogModule,
  ],
  providers: [
    SessionService,
    {provide: MAT_DATE_LOCALE, useValue: SettingsService.language},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [EnvironmentInitService, SettingsService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DeviceInfoInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {panelClass: 'kkm-dialog_panel', hasBackdrop: true, closeOnNavigation: true}},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
